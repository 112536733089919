import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'siteInput',
    'siteUserInput',
  ]

  connect() {
    this.siteUserInputTarget.disabled = true
  }

  siteChange(event) {
    let siteTitle = this.siteInputTarget.options[this.siteInputTarget.selectedIndex].text
    let existingSelection = this.siteUserInputTarget.options[this.siteUserInputTarget.selectedIndex]

    this.siteUserInputTarget.disabled = false

    if(siteTitle != '') {
      // If we have a selection
      this.siteUserInputTarget.querySelectorAll(`optgroup[label="${siteTitle}"]`)
      .forEach(optgroup => optgroup.classList.remove('hidden'))

      this.siteUserInputTarget.querySelectorAll(`optgroup:not([label="${siteTitle}"])`)
      .forEach(optgroup => optgroup.classList.add('hidden'))
    } else {
      // If not, show everything
      this.siteUserInputTarget.querySelectorAll('optgroup')
      .forEach(optgroup => optgroup.classList.remove('hidden'))
    }

    if(existingSelection) {
      existingSelection.selected = false;
    }

  }

}
