import { Controller } from "stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {

  connect() {
    Sortable.create(this.element, {
      handle: '.handle',
      draggable: '.sortable',
      ghostClass: 'ghost',
      animation: 150,
      onUpdate: this.updateSort
    });
  }

  updateSort(event) {
    let params = {
      model: event.item.dataset.sortingModel,
      id: event.item.dataset.sortingModelId,
      position: event.newIndex + 1
    }

    let formData = Object.keys(params).map(key =>
      key + '=' + params[key]
    ).join('&');

    Rails.ajax({
      type: "post",
      url: event.item.dataset.sortingUrl,
      data: formData,
      success: function(data) {
        event.item.classList.add('updated')

        setTimeout(function(){
          event.item.classList.remove('updated')
        }, 1500);
      }
    })
  }

  sortSuccess() {
    debugger;
  }

}
