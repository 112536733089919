import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    'bid'
  ]

  static values = {
    runId: Number
  }

  connect() {
    this.bidStatusChannel = this.setupWebsocketConnection()
  }

  disconnect() {
    this.bidStatusChannel.unsubscribe()
  }

  // showAllLogs(event) {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if(event.target.matches('.toggled') {
  //     event.target.classList
  //   } else {

  //   }

  //   this.bidTargets.forEach((bidTarget) => {
  //     bidTarget.querySelector('.logs.toggle').click()
  //   })
  // }


  setBidStatus(bid) {
    let bidEl = this.findBidStatus(bid)

    if(!bidEl) {
      this.addBidStatus(bid)
    } else {
      bidEl.replaceWith(this.bidStatus(bid))
    }
  }

  addBidStatus(bid) {
    this.element.appendChild(this.bidStatus(bid))
  }

  findBidStatus(bid) {
    return this.bidTargets.find((bidTarget) => parseInt(bidTarget.dataset.id) == bid.id)
  }

  bidStatus(bid) {
    let fragment = document.createElement('template')

    if(!bid.log) {
      bid.log = {
        status: {
          progress: 0,
          message: ''
        },
        recent_logs: []
      }
    }

    if(!bid.log.status.progress) {
      bid.log.status.progress = 0
    }

    if(!bid.message) {
      bid.message= ''
    }

    fragment.innerHTML = `
      <div class='bid' data-bid-status-target="bid" data-id="${bid.id}" data-status="${bid.status}">
        <div class="description">
          <a href="/runs/${this.runIdValue}/bids/${bid.id}">Bid ${bid.identifier}</a>
          — [${bid.status}] ${bid.message}
        </div>
        <div class="progress" style="width: ${bid.log.status.progress}%;"></div>
        <ul class="log">
          ${
            bid.log.recent_logs.map((logLine) => {
              return `<li>${logLine}</li>`
            }).join('')
          }
        </ul>
      </div>
    `

    return fragment.content
  }


  setupWebsocketConnection() {
    return consumer.subscriptions.create(
      {
        channel: "BidStatusChannel",
        run_id: this.runIdValue
      }, {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
    })
  }

  _connected() {
    // Called when the subscription is ready for use on the server
    console.log(`Bid Status Channel Connected`)

    this.bidStatusChannel.perform("populate")
  }

  _disconnected() {
    // Called when the subscription has been terminated by the server
  }

  _received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log(data)

    if(data.delete) {
      this.findBidStatus(data).remove()
    } else {
      this.setBidStatus(data)
    }
  }
}
