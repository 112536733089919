import { Controller } from "stimulus"
import ServerDate from 'helpers/clock'

export default class extends Controller {

  static targets = [
    'clock',
    'clockPrecision'
  ]

  connect() {
    this.clockInterval = setInterval(() => {
      this.setClock()
    }, 1000)
  }

  disconnect() {
    if(this.clockInterval) {
      clearInterval(this.clockInterval)
    }
  }

  setClock() {
    var tday=new Array("Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday");
    var tmonth=new Array("January","February","March","April","May","June","July","August","September","October","November","December");
    var s_date = new ServerDate();
    var d=new Date(s_date);
    var nday=d.getDay(),nmonth=d.getMonth(),ndate=d.getDate(),nyear=d.getYear();
    if(nyear<1000) nyear+=1900;
    var nhour=d.getHours(),nmin=d.getMinutes(),nsec=d.getSeconds(),ap;

    if(nhour==0){ap=" AM";nhour=12;}
    else if(nhour<12){ap=" AM";}
    else if(nhour==12){ap=" PM";}
    else if(nhour>12){ap=" PM";nhour-=12;}

    if(nmin<=9) nmin="0"+nmin;
    if(nsec<=9) nsec="0"+nsec;

    this.clockTarget.innerHTML = tday[nday]+", "+tmonth[nmonth]+" "+ndate+", "+nyear+" "+nhour+":"+nmin+":"+nsec+ap;
    this.clockPrecisionTarget.innerHTML = "&nbsp;±&nbsp;" + ServerDate.getPrecision() + "ms";
  }

}
