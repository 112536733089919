import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    'server'
  ]

  static values = {
    runId: Number
  }

  connect() {
    this.serverStatusChannel = this.setupWebsocketConnection()
  }

  disconnect() {
    this.serverStatusChannel.unsubscribe()
  }

  setServerStatus(server) {
    let serverEl = this.findServerStatus(server)

    if(!serverEl) {
      this.addServerStatus(server)
    } else {
      serverEl.replaceWith(this.serverStatus(server))
    }
  }

  addServerStatus(server) {
    this.element.appendChild(this.serverStatus(server))
  }

  findServerStatus(server) {
    return this.serverTargets.find((serverTarget) => parseInt(serverTarget.dataset.id) == server.id)
  }

  serverStatus(server) {
    let fragment = document.createElement('template')

    server.progress == undefined ? server.progress = 0 : null
    server.message == undefined ? server.message = '' : null

    fragment.innerHTML = `
      <div class="server" data-id="${server.id}" data-server-status-target="server">
        <div class="description">Server ${server.id} — [${server.message}]</div>
        <div class="progress" style="width: ${server.progress}%;"></div>
      </div>
    `

    return fragment.content
  }


  setupWebsocketConnection() {
    return consumer.subscriptions.create(
      {
        channel: "ServerStatusChannel",
        run_id: this.runIdValue
      }, {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
    })
  }

  _connected() {
    // Called when the subscription is ready for use on the server
    console.log("ServerStatusChannel Connected")

    this.serverStatusChannel.perform("populate")
  }

  _disconnected() {
    // Called when the subscription has been terminated by the server
  }

  _received(data) {
    console.log(data)

    if(data.delete) {
      this.findServerStatus(data).remove()
    } else {
      this.setServerStatus(data)
    }
  }
}
