/** Toggle elements */
class JsToggle {

  static toggleableElementSelector = '[data-toggle]'

  /**
   * @constructor
   * @param {event} event - the event handler
   */
  constructor(event) {
    this.event = event
    this.target = this.event.target
    this.selector = this.target.getAttribute('data-toggle')

    if(this.selector.startsWith('#!')) {
      this.event.preventDefault()
      this.event.stopPropagation()

      this.trigger()
    }
  }

  /**
   * Trigger the toggle function
   */
  trigger() {
    this.toggleTarget = document.getElementById( this.selector.replace('#!', '') )

    if(this.toggleTarget !== undefined) {
      this.toggleTarget.classList.toggle('hidden')

      this.maybeHideOthersInGroup()
    }
  }

  maybeHideOthersInGroup() {
    let toggleGroup = this.target.closest('data-toggle-group')

    if(toggleGroup != undefined) {
      toggleGroup.querySelectorAll(this.toggleableElementSelector).filter(toggleable => {
        return toggleable != this.toggleTarget
      }).forEach(toggleable => toggleable.classList.add('hidden'))
    }
  }

}

document.addEventListener('click', (event) => {
  if(event.target.matches('.toggle')) {
    return new JsToggle(event)
  }
})
