// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Polyfill modern JS features, like promises, async functions, etc.
import "core-js/stable";
import "regenerator-runtime/runtime";

// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Icon Font
import '@fortawesome/fontawesome-free/css/all.css';

// Stylesheets
import "stylesheets/application";

// Stimulus JS
import "controllers";

// Helpers
import "helpers/modal";
import "helpers/toggle";
