import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  initialize() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy() {
    this.sourceTarget.select()
    document.execCommand("copy")

    this.addCopiedClass()
    setTimeout(this.removeCopiedClass.bind(this), 2000)
  }


  addCopiedClass() {
    this.element.classList.add('__copied')
  }

  removeCopiedClass() {
    this.element.classList.remove('__copied')
  }
}
