import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'bid',
    'status',
    'bidCount'
  ]

  connect() {
    this.bidTargets.forEach((bid) => this.commitChanges(bid))
  }

  // Actions
  createBid(event) {
    let [data, status, xhr] = event.detail;

    event.target.closest('.bid').parentElement.remove()
    this.insertBidFromTemplate(data)
    this.commitChanges(this.findBid(data.id))

    let bid = this.findBid(data.id)

    // Put a lil success indicator
    bid.classList.add('added')
    setTimeout(function() {
      bid.classList.remove('added')
    }, 700)

    this.bidCountTarget.textContent = this.bidTargets.length
  }

  updateBid(event) {
    let [data, status, xhr] = event.detail;
    let bid = this.findBid(data.id)

    // Put a lil success indicator
    bid.classList.add('updated')
    setTimeout(function() {
      bid.classList.remove('updated')
    }, 700)

    this.commitChanges(bid)
  }

  deleteBid(event) {
    let [data, status, xhr] = event.detail;

    this.findBid(data.id).remove()
    this.bidCountTarget.textContent = this.bidTargets.length
  }

  formReset(event) {
    event.preventDefault()
    event.stopPropagation()

    let bid = event.target.closest('.bid')

    bid.querySelector('.bid_identifier input').value =
      bid.querySelector('.bid_identifier input').getAttribute('data-initial-value')

    bid.querySelector('.bid_percentage input').value =
      bid.querySelector('.bid_percentage input').getAttribute('data-initial-value')

    this.commitChanges(bid)
  }

  formError(event) {
    let [data, status, xhr] = event.detail;
    let errorDiv = document.createElement('div')
    errorDiv.classList.add('error_notification')

    data.errors.forEach((errorMessage) => {
      let error = document.createElement('p')
      error.innerText = errorMessage

      errorDiv.appendChild(error)
      setTimeout(function() {
        errorDiv.remove()
      }, 2000)
    })

    event.target.prepend(errorDiv)
  }

  // Helpers
  findBid(id) {
    return this.bidTargets.find((bid) => parseInt(bid.dataset.id) == id)
  }

  attributeChange(event) {
    if(event.target.hasAttribute('data-initial-value')) {
      let initialValue = event.target.getAttribute('data-initial-value')

      if(initialValue != event.target.value) {
        event.target.classList.add('modified')
        event.target.closest('form').querySelector('.actions').classList.remove('hidden')
        event.target.closest('form').querySelector('input[type=submit]').disabled = false
      } else {
        event.target.classList.remove('modified')
        event.target.closest('form').querySelector('.actions').classList.add('hidden')
        event.target.closest('form').querySelector('input[type=submit]').disabled = true
      }
    }
  }

  insertBidFromTemplate(data) {
    let bidRow = this.element.querySelector('.bid-template').content.cloneNode(true)

    bidRow.querySelector('.bid').setAttribute('data-id', data.id)

    bidRow.querySelector('.bid_identifier input').value = data.identifier
    bidRow.querySelector('.bid_percentage input').value = data.percentage

    bidRow.querySelector('.delete').setAttribute(
      'href',
      bidRow.querySelector('.delete').getAttribute('href').replace('/0', '/' + data.id)
    )
    bidRow.querySelector('.delete').classList.remove('hidden')

    bidRow.querySelector('.reset').classList.remove('hidden')

    bidRow.querySelector('form').id = `edit_bid_${data.id}`
    bidRow.querySelector('form input[name="_method"]').value = 'patch'
    bidRow.querySelector('form').setAttribute(
      'action',
      bidRow.querySelector('form').getAttribute('action').replace('/bids', '/bids/' + data.id)
    )
    bidRow.querySelector('form').setAttribute(
      'data-action',
      bidRow.querySelector('form').getAttribute('data-action').replace('createBid', 'updateBid')
    )

    this.element.querySelector('.bid-container').appendChild(bidRow)
  }

  commitChanges(bid) {
    // Track changes made to identifier and bid percentage
    bid.querySelector('.bid_identifier input').classList.remove('modified')
    bid.querySelector('.bid_identifier input').setAttribute(
      'data-initial-value',
      bid.querySelector('.bid_identifier input').value
    )

    bid.querySelector('.bid_percentage input').classList.remove('modified')
    bid.querySelector('.bid_percentage input').setAttribute(
      'data-initial-value',
      bid.querySelector('.bid_percentage input').value
    )

    bid.querySelector('.actions').classList.add('hidden')
    bid.querySelector('input[type=submit]').disabled = true

  }
}
